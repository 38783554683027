'use strict'

/*------------------------------------*\
 * JS Main entry file
 \*------------------------------------*/
import './config'
import '@utilities/detect-touch'
import '@utilities/detect-keyboard-focus'
import '@components/image'

import { LabelAsPlaceholder } from '@components/form-elements'

import moduleInit from '@/utilities/module-init'

moduleInit.sync('[js-hook-lap]', LabelAsPlaceholder)
moduleInit.async('[js-hook-module-example]', () => import('@components/example')) // Async
moduleInit.async('[js-hook-input-file]', () =>
  import(/* webpackChunkName: "InputFile" */ '@components/form-elements/element/input-file'),
)
moduleInit.async('[js-hook-header]', () => import('@components/header'))
moduleInit.async('[js-hook-mega-menu]', () =>
  import(/* webpackChunkName: "MegaMenu" */ '@components/mega-menu'),
)
moduleInit.async('[js-hook-cookies-bar]', () =>
  import(/* webpackChunkName: "Cookies" */ '@components/cookies'),
)
moduleInit.async('[js-hook-video-wrapper]', () =>
  import(/* webpackChunkName: "Video" */ '@components/video'),
)
moduleInit.async('[js-hook-carousel]', () =>
  import(/* webpackChunkName: "Carousel" */ '@components/carousel'),
)
moduleInit.async('[js-hook-modal]', () =>
  import(/* webpackChunkName: "Modal" */ '@components/modal'),
)
moduleInit.async('[js-hook-quiz]', () => import(/* webpackChunkName: "Quiz" */ '@components/quiz'))

moduleInit.async('[js-hook-intro-text]', () =>
  import(/* webpackChunkName: "Intro Text" */ '@components/intro-text'),
)
moduleInit.async('[js-hook-quick-check]', () =>
  import(/* webpackChunkName: "QuickCheck" */ '@components/quick-check'),
)
moduleInit.async('[js-hook-form]', () =>
  import(/* webpackChunkName: "js/Form" */ '@components/form'),
)
moduleInit.async('[js-hook-contact-wizard]', () =>
  import(/* webpackChunkName: "ContactWizard" */ '@components/contact-wizard'),
)
moduleInit.async('[js-hook-navigation]', () =>
  import(/* webpackChunkName: "Navigation" */ '@components/navigation'),
)
moduleInit.async('[js-hook-interactive-map]', () =>
  import(/* webpackChunkName: "InteractiveMap" */ '@components/interactive-map'),
)
